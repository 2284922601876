'use strict'; /*jshint loopfunc: true */
/*jshint bitwise: false */

/**
 * @param {object} $q
 * @param {object} FileUploader
 * @param {object} $translate
 * @param {object} $log
 * @param {object} $modal
 * @param {object} $state
 * @param {object} $http
 * @param {object} configuration
 * @param {object} alertsService
 * @param {object} fileService
 * @param {object} Document
 * @param {object} Link
 * @param {object} jwtSessionService
 * @param {object} cmisService
 * @param {object} userSessionService
 * @param {object} piecesService
 * @param {object} jsonpatch
 * @param {object} $timeout
 * @description Directive who display a piece and this associated documents
 * @example
 * <piece-form
 view-configuration="piecesConfiguration"
 ng-model="piece"
 url-documents="urlDocuments"
 aide="aide"
 tiers="tiers"
 file-extensions-allowed="mergedConfiguration.fileExtensionsAllowed"
 file-size-limit="mergedConfiguration.fileSizeLimit"
 icons-set-name="mergedConfiguration.iconsSetName"
 authorize-delete-document-server="true"
 url-file-icons="urlFileIcons"
 display-status="true"
 max-documents-authorized="maxDocumentsAuthorized"
 is-active-one-document-by-piece="unDocumentParPiece"
 validate="updatePiece(persistence)"
 is-required="piece.obligatoire"
 after-upload="onDocumentUploaded(documentPiece)"
 entity="aide"
 kind="'aide'"
 disable-validate-button="disableValidateButton(disable)"
 is-upload-in-progress="isUploadInProgress">
 </piece-form>
 * @returns {object}
 */
angular.module('common.directives').directive('pieceForm', [
  '$q',
  'FileUploader',
  '$translate',
  '$log',
  '$modal',
  '$state',
  '$http',
  'configuration',
  'alertsService',
  'fileService',
  'Document',
  'Link',
  'jwtSessionService',
  'cmisService',
  'userSessionService',
  'piecesService',
  'jsonpatch',
  '$timeout',
  function (
    $q,
    FileUploader,
    $translate,
    $log,
    $modal,
    $state,
    $http,
    configuration,
    alertsService,
    fileService,
    Document,
    Link,
    jwtSessionService,
    cmisService,
    userSessionService,
    piecesService,
    jsonpatch,
    $timeout
  ) {
    'use strict';

    return {
      replace: true,
      transclude: true,
      require: ['^form', 'ngModel'],
      templateUrl: 'common/common-directives/piece-form/piece-form.html',
      scope: {
        viewConfiguration: '=',
        fileExtensionsAllowed: '=?',
        piece: '=ngModel',
        iconsSetName: '=?',
        tiers: '=',
        aide: '=',
        displayStatus: '=',
        fileSizeLimit: '=?',
        urlDocuments: '=',
        urlFileIcons: '=?',
        maxDocumentsAuthorized: '=?',
        isActiveOneDocumentByPiece: '=?',
        authorizeDeleteDocumentServer: '=',
        readOnly: '=',
        validate: '&',
        beforeUpload: '&',
        afterUpload: '&',
        setPiecesValidity: '&?',
        isRequired: '=?',
        entity: '=',
        kind: '=',
        remoteValidation: '=?',
        disableValidateButton: '&',
        contribution: '=',
        isComplement: '<',
        updateAide: '&',
        isUploadInProgress: '=',
        isExpandedByDefault: '<',
      },

      link: function (scope, element, attributes, controllers) {
        scope.isActiveOneDocumentByPiece = scope.isActiveOneDocumentByPiece || false;
        scope.form = controllers[0];
        scope.model = controllers[1];
        scope.errorMessage = null;
        scope.isDeletionInProgress = false;

        /**
         * Function who display or hide the document's list of a piece
         *
         * @param {boolean} expanded expanded
         */
        scope.displayListDocuments = function (expanded) {
          if (scope.piece) {
            scope.piece.displayListDocuments = expanded;
          }
        };

        // Avoid undefined case and keep the current behavior
        scope.isExpanded = scope.isExpandedByDefault === false ? false : true;
        scope.displayListDocuments(scope.isExpanded);

        const isDemandeInDepotDelegue =
          scope.aide?.beneficiaires?.length > 0 && scope.aide?.demandeur?.href !== scope.aide?.beneficiaires?.[0]?.href;

        const planFinancementTemplate = _.get(
          scope,
          'aide.teleservice.expand.workflow.simple.pageDocumentComptable.typeDocumentComptable.planFinancement',
          _.get(
            scope,
            'aide.teleservice.expand.workflow.pageDocumentComptable.typeDocumentComptable.planFinancement',
            {}
          )
        );

        // Read remote validation from directive, configuration or viewConfiguration
        scope.remoteValidation =
          scope.remoteValidation !== undefined
            ? scope.remoteValidation
            : _.get(scope, 'viewConfiguration.remoteValidation') !== undefined
              ? _.get(scope, 'viewConfiguration.remoteValidation')
              : false;

        // Logged-in user
        scope.user = userSessionService.getUser();

        // Expenses
        const availablePostesForAssociation = getAvailablePostesForAssociation();
        scope.enableExpenses = canPieceBeAssociated();
        if (scope.enableExpenses) {
          scope.toggleDisplayRelatedExpenses = (documentId) => {
            const displayRelatedExpensesPath = `expenses["${documentId}"].displayRelatedExpenses`;
            _.set(scope, displayRelatedExpensesPath, !_.get(scope, displayRelatedExpensesPath, false));
          };
          computeExpenses();
        }

        scope.hasMoreExpensesThanLimit = function (documentId) {
          const expensesNumber = _.get(scope, `expenses["${documentId}"].related.items`, []).length;
          const limit = _.get(scope, 'expenses.limit', 0);
          return expensesNumber > limit;
        };

        // Text to be displayed in tooltips
        $translate.refresh().then(function () {
          scope.tooltip = {
            add: {
              title: $translate.instant(scope.viewConfiguration.ns + '.addButtonTooltip'),
            },

            briefcase: {
              title: $translate.instant(scope.viewConfiguration.ns + '.briefcaseButtonTooltip'),
            },

            deleteDocument: {
              title: $translate.instant(scope.viewConfiguration.ns + '.deleteDocumentButtonTooltip'),
            },

            postal: {
              title: $translate.instant(scope.viewConfiguration.ns + '.unableToSendElectronicDocument'),
              alt: $translate.instant(scope.viewConfiguration.ns + '.postalSending'),
            },
          };
        });

        // Uploader is in the scope for we can iterate on it and display all documents
        scope.uploader = null;

        // Tiers's documents
        scope.documentsTiers = [];

        // Default persistence configuration
        const persistenceKind = scope.kind === 'contribution' ? 'aide' : scope.kind; // contributions se base sur l'aide
        const defaultPersistenceConfiguration = _.get(
          configuration,
          "persistenceConfiguration['" + persistenceKind + "']",
          configuration.persistenceConfiguration.default
        );

        scope.fileExtensionsAllowed = scope.fileExtensionsAllowed || defaultPersistenceConfiguration.allowedExtensions;

        scope.fileSizeLimit = scope.fileSizeLimit || defaultPersistenceConfiguration.maxDocumentSize;
        scope.maxDocumentsAuthorized =
          scope.maxDocumentsAuthorized || defaultPersistenceConfiguration.maxNumberDocumentsPerPersistence;

        // Icons set name
        scope.iconsSetName = scope.iconsSetName || 'standard';

        // Url directory file icons
        scope.urlFileIcons = scope.urlFileIcons || '/module/tiers-resources/images/file-icons/';

        if (Array.isArray(scope.piece?.documents)) {
          for (const doc of scope.piece.documents) {
            if (doc?.error?.code) {
              scope.errorMessage = $translate.instant(`${scope.viewConfiguration.ns}.error.someDocumentNotFound`);
            }
          }
        }

        /**
         * Function who return the url to access to the directory file icons
         *
         * @returns {string} Url to access file icons
         */
        scope.getUrlFileIcons = function () {
          if (scope.urlFileIcons.slice(-1) !== '/') {
            scope.urlFileIcons += '/';
          }

          return scope.urlFileIcons;
        };

        /**
         * Method for validating whether a document list is valid
         *
         * @param {object[]} documents
         * @returns {boolean}
         */
        function areDocumentsValid(documents) {
          // We're checking to make sure the list isn't empty.
          if (_.isEmpty(documents)) {
            return false;
          }
          let isValid = true;
          _.forEach(documents, (doc) => {
            // We check that for each document the href and the id are not null.
            isValid =
              isValid && !angular.isUndefined(doc) && !angular.isUndefined(doc.id) && !angular.isUndefined(doc.href);
          });
          return isValid;
        }

        /**
         * check the validity, a required piece is expected to contains documents
         *
         * @returns {boolean}
         */
        function checkValidity() {
          let validity = true;
          if (
            scope.isRequired &&
            !areDocumentsValid(scope.piece.documents) &&
            (scope.piece.modeTransmission === 'DEPOSEE' || scope.piece.reference === 'attestationDeclarationHonneur')
          ) {
            validity = false;
          }
          return validity;
        }

        /**
         * Filter for fileExtensionsAllowed - Item can be a file object if the browser handles or a html input file element
         *
         * @param {object} item File
         * @returns {boolean}
         */
        function filterFileExtensionsAllowed(item) {
          const isExtensionAuthorized = fileService.isExtensionAuthorized(item, scope.fileExtensionsAllowed);
          if (!isExtensionAuthorized) {
            scope.errorMessage = $translate.instant(`${scope.viewConfiguration.ns}.error.extensionUnauthorized`, {
              extensions: scope.fileExtensionsAllowed.join(', ') || 'pdf, png, jpg, jpeg, doc, docx',
            });
          }

          return isExtensionAuthorized;
        }

        /**
         * Show alert about the number of documents
         *
         * @param {number} maxDocumentsAuthorized
         * @returns {void}
         */
        function showDocumentsNumberAlert(maxDocumentsAuthorized) {
          if (maxDocumentsAuthorized === 1) {
            scope.errorMessage = $translate.instant(`${scope.viewConfiguration.ns}.error.uniqueDocumentAuthorized`);
          } else {
            scope.errorMessage = $translate.instant(`${scope.viewConfiguration.ns}.error.maxDocumentsAuthorized`);
          }
        }

        const maxDocumentsAuthorized = scope.isActiveOneDocumentByPiece ? 1 : scope.maxDocumentsAuthorized;
        const documentsSize = (Array.isArray(scope.piece.documents) && scope.piece.documents.length) || 0;

        // if the number of documents is above the authorized number, show alert
        if (documentsSize > maxDocumentsAuthorized) {
          if (scope.setPiecesValidity) scope.setPiecesValidity({ isValid: false });
          $timeout(() => {
            showDocumentsNumberAlert(maxDocumentsAuthorized);
          });
        }

        /**
         * Check if it is possible to upload a document by
         * comparing number of docs authorized WITH already uploaded/uploading docs
         * Display a message if the max number of upload is reached
         *
         * @returns {boolean} isUploadAuthorized
         */
        function filterMaxDocumentsAuthorized() {
          // To manage multi upload, we up the number of document currently uploading since filters all call for everydocument before going to success or error
          // since we are checking the piece.documents it will be not update between each document

          let isUploadAuthorized = true;
          const maxDocumentsAuthorized = scope.isActiveOneDocumentByPiece ? 1 : scope.maxDocumentsAuthorized;

          if (_.get(scope, 'piece.documents', []).length + scope.uploader.queue.length >= maxDocumentsAuthorized) {
            isUploadAuthorized = false;
            showDocumentsNumberAlert(maxDocumentsAuthorized);
          }

          return isUploadAuthorized;
        }

        /**
         * Filter for the check the size of the file
         *
         * @param {object} file File before upload
         * @returns {boolean}
         */
        function filterFileSize(file) {
          let isSizeOk = true;

          // Size to 0
          if (file && file.size === 0) {
            scope.errorMessage = $translate.instant(`${scope.viewConfiguration.ns}.error.fileSizeZero`, {
              fileSizeLimit: scope.fileSizeLimit,
            });

            isSizeOk = false;
          }

          // File's size limit
          if (scope.fileSizeLimit && scope.fileSizeLimit > 0 && file && file.size && file.size > 0) {
            const sizeFileMo = file.size / 1024 / 1024;
            if (sizeFileMo > scope.fileSizeLimit) {
              scope.errorMessage = $translate.instant(`${scope.viewConfiguration.ns}.error.fileSizeLimitExceeded`, {
                fileSizeLimit: scope.fileSizeLimit,
              });

              isSizeOk = false;
            }
          }

          return isSizeOk;
        }

        /**
         * @returns {string}
         */
        function generateUUID() {
          /**
           * @returns {string}
           */
          function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
          }

          const guid = (
            S4() +
            S4() +
            '-' +
            S4() +
            '-4' +
            S4().substr(0, 3) +
            '-' +
            S4() +
            '-' +
            S4() +
            S4() +
            S4()
          ).toLowerCase();
          return guid;
        }

        /**
         * Defines the url of the document so that it
         * can be downloaded or deleted.
         * Method that takes into account the fact
         * that one comes from a GED or datacollect-persistence
         *
         * @param {string} objectId
         * @returns {string[]}
         */
        function getUrlDocuments(objectId) {
          let urlDocuments = scope.urlDocuments;
          if (_.includes(urlDocuments, 'document-collect')) {
            urlDocuments = _.first(urlDocuments.split('/root'));
            urlDocuments += '/root?objectId=' + objectId;
          } else {
            urlDocuments += '/' + objectId;
          }

          return urlDocuments;
        }

        /**
         *
         * @param {object} fileItem
         */
        function onBeforeUpload(fileItem) {
          // clear existing alerts
          scope.hideAlertMessage();

          // Pass cmis parameters
          let guidfileName = generateUUID() + '_' + fileItem.file.name;
          // limitation of filename 220 for the new name in the ged (from 248 characters this is a problem),
          // because then you can add extensions
          if (guidfileName.length >= 220) {
            const extensionFile = guidfileName.substring(guidfileName.lastIndexOf('.'));
            const nameFile = guidfileName.substring(0, guidfileName.length - extensionFile.length);
            guidfileName = nameFile.substring(0, 220 - extensionFile.length) + extensionFile;
          }

          let kindMetadata = _.toLower(_.get(scope, 'entity.kind', scope.kind)); // Note: _.toLower return an empty string for null or undefined values.
          if (kindMetadata === 'contribution') {
            switch (scope.contribution.typeContribution) {
              case 'MODIFICATION':
                kindMetadata = 'contribution-modification';
                break;
              case 'AVIS':
                kindMetadata = 'contribution-avis';
                break;
              case 'AVIS_FINANCEMENT':
                kindMetadata = 'contribution-avis-financement';
                break;
              case 'REDIRECTION':
                kindMetadata = 'contribution-redirection';
                break;
              default:
                break;
            }
          }
          let entityUri = scope.entity?.id;
          let entityReference = scope.entity?.reference;
          let entityReferenceAdministrative = scope.entity?.referenceAdministrative;

          // If scope.entity.demande is undefined, it means that scope.entity is already the demande (and not the contrib)
          // In fact this is only true, on depot with contribution-modification, on page piece
          if (scope.kind === 'contribution' && scope.entity.demande) {
            // contribution are uploaded in the demande-financement repository, entityUri must be demande id
            entityUri = scope.entity?.demande?.id;
            entityReference = scope.entity?.demande?.reference;
            entityReferenceAdministrative = scope.entity?.demande?.referenceAdministrative;
          }

          const params = {
            cmisaction: 'createDocument',
            'propertyId[0]': 'cmis:name',
            'propertyValue[0]': guidfileName,
            'propertyId[1]': 'cmis:objectTypeId',
            'propertyValue[1]': 'cmis:document',
            'propertyId[2]': 'cmis:contentStreamLength',
            'propertyValue[2]': fileItem.file.size,
            'propertyId[3]': 'cmis:contentStreamMimeType',
            'propertyValue[3]': fileItem.file.type,
            'propertyId[4]': 'cmis:secondaryObjectTypeIds',
            'propertyValue[4]': 'entity:metadata',
            'propertyId[5]': 'entity:uri',
            'propertyValue[5]': entityUri ?? '',
            'propertyId[6]': 'entity:reference',
            'propertyValue[6]': entityReference ?? '',
            'propertyId[7]': 'entity:referenceAdministrative',
            'propertyValue[7]': entityReferenceAdministrative ?? '',
            'propertyId[8]': 'entity:document:originalfilename',
            'propertyValue[8]': fileItem.file.name,
            'propertyId[9]': 'entity:kind',
            'propertyValue[9]': kindMetadata,
            'propertyId[10]': 'entity:author',
            'propertyValue[10]': _.get(scope.user, 'displayName', ''),
            'propertyId[11]': 'entity:document:date',
            'propertyValue[11]': new Date().toISOString(),
            'propertyId[12]': 'entity:document:category',
            'propertyValue[12]': _.get(scope, 'piece.reference', ''),
            'propertyId[13]': 'entity:document:nature',
            'propertyValue[13]': _.get(scope, 'piece.nature', ''),
            // Keys retained for upward compatibility
            'propertyId[14]': 'entity:originalfilename',
            'propertyValue[14]': fileItem.file.name,
            'propertyId[15]': 'entity:category',
            'propertyValue[15]': _.get(scope, 'piece.reference', ''),
          };

          fileItem.url += '?' + $.param(params);

          const documentPiece = new Link({
            expand: new Document(),
          });

          documentPiece.expand.properties['cmis:name'] = {};
          documentPiece.expand.properties['cmis:name'].value = fileItem.file.name;
          documentPiece.expand.properties['cmis:description'] = {};
          documentPiece.expand.properties['cmis:description'] = {
            value: null,
          };

          documentPiece.expand.properties['cmis:contentStreamLength'] = {};
          documentPiece.expand.properties['cmis:contentStreamLength'].value = fileItem.file.size;
          documentPiece.expand.properties['cmis:contentStreamMimeType'] = {};
          documentPiece.expand.properties['cmis:contentStreamMimeType'].value = fileItem.file.type;
          documentPiece.expand.isDeletable = false;
          scope.piece.documents = scope.piece.documents || [];
          scope.piece.documents.push(documentPiece);
          fileItem.documentPiece = documentPiece;

          if (scope.beforeUpload) {
            scope.beforeUpload();
          }
        }

        /**
         *
         * @param {object} fileItem
         * @param {number} progress
         */
        function onProgress(fileItem, progress) {
          scope.piece.hasDocumentOnProgress = true;
          scope.disableValidateButton({ disable: true });
          fileItem.documentPiece.expand.isUploading = true;
          fileItem.documentPiece.expand.progress = progress;
        }

        /**
         *
         * @param {object} fileItem
         * @param {object} response
         */
        function onSuccess(fileItem, response) {
          const documentPiece = fileItem.documentPiece;
          // Retrieving a CMIS response
          if (response.properties) {
            documentPiece.expand = documentPiece.expand || {};
            documentPiece.expand.properties = response.properties;
          } else {
            documentPiece.expand.properties['cmis:objectId'] = {};
            documentPiece.expand.properties['cmis:objectId'].value = response._id;
          }
          if (
            response.properties &&
            response.properties['cmis:name'] &&
            response.properties['entity:originalfilename']
          ) {
            _.set(documentPiece.expand.properties, 'cmis:name.value', _.get(response, 'properties.cmis:name.value'));
            _.set(
              documentPiece.expand.properties,
              'entity:originalfilename.value',
              _.get(response, 'properties.entity:originalfilename.value')
            );
          }

          documentPiece.rel = 'cmis';

          const objectId = documentPiece.expand.properties['cmis:objectId'].value;
          documentPiece.id = getUrlDocuments(objectId);

          // href management if the document has been uploaded to datacollect-persistence
          if (response._id) {
            documentPiece.href = documentPiece.id;
          } else {
            const queryParamSeparator = documentPiece.id.indexOf('?') >= 0 ? '&' : '?';
            documentPiece.href = `${documentPiece.id}${queryParamSeparator}cmisselector=object`;
          }
          if (response.properties && response.properties['entity:originalfilename']) {
            documentPiece.title = response.properties['entity:originalfilename'].value;
          }

          documentPiece.expand.properties['cmis:creationDate'] = {};
          documentPiece.expand.properties['cmis:creationDate'].value = new Date().toISOString();
          documentPiece.expand.isUploaded = true;
          documentPiece.expand.isDeletable = true;
          documentPiece.expand.isUploading = false;
          documentPiece.expand.progress = 100;
          delete scope.piece.hasDocumentOnProgress;

          computeExpenses();
          if (scope.afterUpload) {
            scope.afterUpload({ documentPiece: documentPiece });
          }
        }

        /**
         *
         * @param {object} fileItem
         * @param {object} response
         * @param {number} status
         */
        function onError(fileItem, response, status) {
          scope.disableValidateButton({ disable: false });
          delete scope.piece.hasDocumentOnProgress;

          // Document already exist on the server
          if (status === 409) {
            scope.errorMessage = $translate.instant(`${scope.viewConfiguration.ns}.error.conflict`);
          }
          // File too big for the server
          else if (status === 413) {
            scope.errorMessage = $translate.instant(`${scope.viewConfiguration.ns}.error.fileSizeLimitExceededServer`);
          }
          // File corrupted
          else if (response.status === 400.1) {
            scope.errorMessage = $translate.instant(`${scope.viewConfiguration.ns}.error.corrupted`);
          } else if (status === 401) {
            $state.go('app.home');
          } else {
            scope.errorMessage = $translate.instant(`${scope.viewConfiguration.ns}.error.unknow`);
          }

          // Delete item from the list of documents
          scope.piece.documents.splice(_.indexOf(scope.piece.documents, fileItem.documentPiece), 1);

          if (scope.afterUpload) {
            scope.afterUpload();
          }
        }

        const onCompleteAll = function () {
          scope.disableValidateButton({ disable: false });
          if (scope.validate) {
            scope.validate();
          }
        };

        const onCompleteItem = function (item) {
          scope.uploader.removeFromQueue(item);
        };

        /**
         * Download a document
         *
         * @param  {object} pieceDocument Document of the piece
         */
        scope.downloadDocument = function (pieceDocument) {
          const url = pieceDocument.id;

          $http
            .get(url, {
              responseType: 'arraybuffer',
            })
            .then(function (reponse) {
              const mimeType = pieceDocument.expand.properties['cmis:contentStreamMimeType'].value;
              const blob = new Blob([reponse.data], {
                type: mimeType,
              });

              const documentName =
                _.get(pieceDocument, 'expand.properties.entity:originalfilename.value') ||
                _.get(pieceDocument, 'expand.properties.cmis:name.value');
              saveAs(blob, documentName);
            })
            .catch(function (error) {
              $log.error(error);
              if (error.status === 404) {
                scope.errorMessage = $translate.instant(`${scope.viewConfiguration.ns}.error.downloadUnexistDocument`);
              } else {
                scope.errorMessage = $translate.instant(`${scope.viewConfiguration.ns}.error.unknow`);
              }
            });
        };

        /**
         * Function who hide alert message
         */
        scope.hideAlertMessage = function () {
          scope.errorMessage = null;
        };

        /**
         * Return the path of the icon.
         *
         * @param  {string} fileName File's name
         * @returns {string}          File's icon
         */
        scope.getIconDocument = function (fileName) {
          let iconExtension = 'default';
          const iconsExtensions = [
            'ai',
            'doc',
            'docx',
            'eps',
            'gif',
            'html',
            'jpeg',
            'jpg',
            'mp3',
            'mp4',
            'pdf',
            'png',
            'ppt',
            'pptx',
            'psd',
            'rar',
            'txt',
            'xls',
            'xlsx',
            'xml',
            'zip',
          ];

          const extensionFile = fileService.getExtension(fileName);
          if (extensionFile && _.includes(iconsExtensions, extensionFile)) {
            iconExtension = extensionFile;
          }

          return iconExtension;
        };

        /**
         * Function who format bytes
         *
         * @param  {number} bytes Number of bytes
         * @returns {string}       Bytes formatted
         */
        scope.formatSizeFile = fileService.formatSizeFile;

        /**
         * Remove document from the persistence
         *
         * @param {object} modal Modal to close when ok
         * @param {object} documentPiece Document to remove
         */
        scope.removeDocument = function (modal, documentPiece) {
          scope.isDeletionInProgress = true;

          // Hide error message if exist
          scope.hideAlertMessage();

          /**
           *
           */
          function deleteDocumentOnEntityPiece() {
            if (documentPiece.expand.isUploading) {
              scope.disableValidateButton({ disable: false });
            }

            // Delete item from the list of documents
            scope.piece.documents = scope.piece.documents.filter((doc) => doc.id !== documentPiece.id);

            if (scope.aide) {
              const observer = jsonpatch.observe(scope.aide);

              // Remove association
              _.forEach(
                JSONPath('aide.planFinancement[*].depense..lignes[?(@.pieces && @.pieces.length)]', scope),
                (ligne) => {
                  // we use a forEachRight here to avoid a problem of index
                  _.forEachRight(ligne.pieces, (piece, pieceIndex) => {
                    _.remove(piece.documents, (d) => d.id === documentPiece.id);
                    if (_.isEmpty(piece.documents)) {
                      ligne.pieces.splice(pieceIndex, 1);
                    }
                  });
                  if (_.isEmpty(ligne.pieces)) {
                    delete ligne.pieces;
                  }
                }
              );

              const patches = jsonpatch.generate(observer);

              if (scope.isComplement && patches.length) {
                scope.updateAide({ patches });
              }
            }

            modal.$hide();

            if (scope.validate) {
              scope.validate();
            }
          }

          // if this is invalid, the document has been remove form the GED
          const isExpandValid = !_.isEmpty(_.get(documentPiece, 'expand.properties'));

          // The condition on entity:uri allows to know if the document is referenced as a structure part.
          if (scope.authorizeDeleteDocumentServer && isExpandValid) {
            piecesService
              .deleteDocumentOnGED(documentPiece, scope.urlDocuments)
              .then(() => deleteDocumentOnEntityPiece())
              .then(() => {
                const documentsSize = scope.piece.documents.length;

                // if the number of documents is within the authorized range, enable next
                if (documentsSize <= maxDocumentsAuthorized) {
                  if (scope.setPiecesValidity) scope.setPiecesValidity({ isValid: true });
                }

                scope.isDeletionInProgress = false;
              })
              .catch((error) => {
                scope.isDeletionInProgress = false;
                $log.error(error);
                if (error && error.status === 404) {
                  deleteDocumentOnEntityPiece();
                } else if (error && error.status === 401) {
                  modal.$broadcast(
                    'alerts',
                    alertsService.getAlertError(scope.viewConfiguration.ns + '.confirmRemoveDocument.unauthorized'),
                    'remove-account-confirm'
                  );
                } else {
                  modal.$broadcast(
                    'alerts',
                    alertsService.getAlertError(scope.viewConfiguration.ns + '.confirmRemoveDocument.error'),
                    'remove-account-confirm'
                  );
                }
              });
          } else {
            deleteDocumentOnEntityPiece();
            scope.isDeletionInProgress = false;
          }
        };

        /**
         * Associate documents to poste
         *
         * @param {object} documentPiece
         * @param {Array} targetLignes
         */
        function associatePieces(documentPiece, targetLignes) {
          const lignes = JSONPath('aide.planFinancement[*].depense..lignes[*]', scope);
          _.forEach(lignes, (ligne) => {
            // Remove the document from all lines
            let lignePiece = _.find(_.get(ligne, 'pieces', []), (p) => p.reference === scope.piece.reference);
            if (lignePiece) {
              _.remove(lignePiece.documents, (d) => d.id === documentPiece.id);
            }

            // Add document to target lines
            if (targetLignes.includes(ligne.reference)) {
              // Create the piece if it doesn't exist
              if (!lignePiece) {
                lignePiece = _.cloneDeep(scope.piece);
                lignePiece.documents = [];
                if (!ligne.pieces) {
                  ligne.pieces = [];
                }
                ligne.pieces.push(lignePiece);
              }

              // Create piece documents if not exists
              if (!lignePiece.documents) {
                lignePiece.documents = [];
              }

              // Add the document
              lignePiece.documents.push(_.pick(documentPiece, ['id', 'href', 'title', 'rel']));
            }

            // Remove pieces if there is no more documents or pieces
            if (ligne.pieces) {
              _.remove(ligne.pieces, (p) => _.isEmpty(p.documents));
              if (!ligne.pieces.length) {
                delete ligne.pieces;
              }
            }
          });
          computeExpenses();
        }

        /**
         * Update description's document
         *
         * @param  {object} documentPiece Document
         */
        scope.updateDescriptionDocument = function (documentPiece) {
          if (documentPiece.rel === 'cmis') {
            if (scope.beforeUpload) {
              scope.beforeUpload({ updatingDescription: true });
            }

            // using a timeout here is necessary because it allows to detect the click on "follow" button if it's done simultaneously to the blur of the description field
            $timeout(() => {
              let success = false;

              const query = {
                method: 'POST',
                params: {
                  cmisaction: 'update',
                  'propertyId[1]': 'cmis:description',
                  'propertyValue[1]': documentPiece.expand.properties['cmis:description'].value,
                },

                url: documentPiece.id,
              };

              $http(query)
                .then(function () {
                  if (scope.validate) {
                    scope.validate();
                  }
                  success = true;
                })
                .catch(function (err) {
                  $log.error(err);
                  if (err.status === 404) {
                    scope.errorMessage = $translate.instant(
                      `${scope.viewConfiguration.ns}.error.updateUnexistDocument`
                    );
                  } else {
                    scope.errorMessage = $translate.instant(`${scope.viewConfiguration.ns}.error.description`, {
                      details: JSON.stringify(err),
                    });
                  }
                })
                .finally(function () {
                  if (scope.afterUpload) {
                    scope.afterUpload({ updatingDescription: true, success });
                  }
                });
            }, 200);
          }
        };

        /**
         * Display a modal for confirm remove document
         *
         * @param {object} documentPiece
         */
        scope.confirmRemoveDocument = function (documentPiece) {
          const scopeModal = scope.$new();
          scopeModal.documentPiece = documentPiece;
          scopeModal.removeDocument = scope.removeDocument;
          $modal({
            scope: scopeModal,
            viewConfiguration: scope.viewConfiguration,
            template: 'common/common-directives/piece-form/modal/confirm-remove-document.html',
            backdrop: 'static',
          });
        };

        /**
         * Returns true if the piece is included in piecesObligatoires of the line or the category for the poste created by the user.
         *
         * @param {object} line
         * @returns {boolean} True if the piece is included in piecesObligatoires of the line
         */
        scope.isPieceObligatoireForLigne = function (line) {
          // The referent poste is the line or the category for the poste created by the user.
          let referentPoste = line;
          if (line.createdByUser) {
            [referentPoste] = JSONPath(
              `depense.postes[?(@.lignes.find(ligne => ligne.reference == "${line.reference}"))]`,
              scope.aide.planFinancement[0]
            );
          }

          // Get required piece from referent poste, matching the current piece
          return !_.isEmpty(
            JSONPath(
              `depense.postes..[?(@.reference == "${referentPoste.reference}")].piecesObligatoires[?(@.reference == "${scope.piece.reference}")]`,
              planFinancementTemplate
            )
          );
        };

        /**
         * Open the modal allowing the user to associate pieces to postes
         *
         * @param {object} documentPiece
         */
        scope.addRelatedExpenses = function (documentPiece) {
          const scopeModal = scope.$new();
          scopeModal.documentPiece = documentPiece;

          // Get available postes for this document
          scopeModal.postes = availablePostesForAssociation;

          // Get related items
          // If we have items, this is a modification, else, this is an add
          // Initialize selection
          scopeModal.relatedItems = _.get(scope, `expenses["${documentPiece.id}"].related.items`, []);
          scopeModal.isEdit = scopeModal.relatedItems.length > 0;
          scopeModal.selection = scopeModal.relatedItems.reduce((acc, item) => {
            acc[item.ligne.reference] = true;
            return acc;
          }, {});

          scopeModal.onConfirm = function () {
            // Get checked postes
            const selectedPostes = Object.keys(scopeModal.selection).reduce((acc, key) => {
              if (scopeModal.selection[key]) {
                acc.push(key);
              }
              return acc;
            }, []);

            // Make association
            associatePieces(documentPiece, selectedPostes);
          };

          $modal({
            scope: scopeModal,
            viewConfiguration: scope.viewConfiguration,
            template: 'common/common-directives/piece-form/modal/add-related-expenses.html',
            backdrop: 'static',
          });
        };

        /**
         * Check if the documents of the piece (pieceAide) contains the given document.
         *
         * @param {object} documentTiers
         * @param {object} pieceAide
         * @returns {boolean}
         */
        scope.checkIfDocumentSelectedOnPiece = function (documentTiers, pieceAide) {
          const documentTiersName = _.get(documentTiers, 'expand.properties["cmis:name"].value');
          return _.some(pieceAide.documents, ['expand.properties["cmis:name"].value', documentTiersName]);
        };

        /**
         * Display modal for select documents from tiers
         */
        scope.selectDocumentsTiers = function () {
          const scopeModal = scope.$new();
          scopeModal.associatedDocumentsFromTiers = scope.associatedDocumentsFromTiers;

          $modal({
            scope: scopeModal,
            piece: scope.piece,
            viewConfiguration: scope.viewConfiguration,
            tiers: scope.tiers,
            configuration: scope.configuration,
            checkIfDocumentSelectedOnPiece: scope.checkIfDocumentSelectedOnPiece,
            documentsTiers: scope.documentsTiers,
            template: 'common/common-directives/piece-form/modal/select-documents.html',
            backdrop: 'static',
          });
        };

        /**
         * Function who retrive documents selected from briefcase for associated this with piece
         *
         * @param {object} modal Modal
         * @param {object} documents Documents from tiers
         */
        scope.associatedDocumentsFromTiers = function (modal, documents) {
          // getting all the selected documents of the porte-document but not already added in the piece
          const documentsSelected = documents?.filter((documentPieceFromTiers) => {
            return (
              documentPieceFromTiers.expand?.selected &&
              !modal.checkIfDocumentSelectedOnPiece(documentPieceFromTiers, scope.piece)
            );
          });

          if (documentsSelected && documentsSelected.length > 0) {
            // Copy all documents from tiers for this piece on the aide
            const allPromises = _.map(documentsSelected, function (documentTiers) {
              // cloning the document to avoid porte-document's document modification
              const copyDocumentTiers = angular.copy(documentTiers);
              copyDocumentTiers.expand.isDeletable = true;
              // expand.selected is only managed by the popin
              _.unset(documentTiers, 'expand.selected');

              scope.piece.documents = scope.piece.documents || [];

              const copyDocumentURL = cmisService.getBaseUrl() + '/aides/' + scope.aide.reference;
              return cmisService
                .copyDocument(copyDocumentURL, copyDocumentTiers, 'aide', scope.aide)
                .then(function (documentPiece) {
                  if (_.get(documentTiers, 'expand.properties.entity:uri.value')) {
                    documentPiece.origin = _.get(documentTiers, 'expand.properties.entity:uri.value');
                  }

                  if (!scope.piece.documents) {
                    scope.piece.documents = [];
                  }
                  scope.piece.documents.push(documentPiece);
                });
            });

            $q.all(allPromises).then(function () {
              modal.$hide();
              if (scope.validate) {
                scope.validate();
              }
            });
          } else {
            modal.$hide();
          }
        };

        // Configuration component upload file - angular-file-upload  - https://github.com/nervgh/angular-file-upload
        scope.$watch('urlDocuments', function (newValue) {
          if (newValue) {
            // Definition of the uploader
            scope.uploader = new FileUploader({
              scope: scope,
              url: scope.urlDocuments,
              autoUpload: true,
              method: 'POST',
              headers: {
                Authorization: 'Bearer ' + jwtSessionService.jwt(),
                'mg-authentication': true,
              },
            });

            // Filter for fileExtensionsAllowed - Item can be a file object if the browser handles or a html input file element
            scope.uploader.filters.push({
              name: 'filterfileExtensionsAllowed',
              fn: filterFileExtensionsAllowed,
            });

            // Filter for check the size of a file
            scope.uploader.filters.push({
              name: 'filterFileSize',
              fn: filterFileSize,
            });

            // Max files upload
            scope.uploader.filters.push({
              name: 'filterMaxDocumentsAuthorized',
              fn: filterMaxDocumentsAuthorized,
            });

            scope.uploader.onBeforeUploadItem = onBeforeUpload;

            // Recover the list of documents after a document has been uploaded
            scope.uploader.onSuccessItem = onSuccess;

            // Error handling
            scope.uploader.onErrorItem = onError;

            // Progress handling
            scope.uploader.onProgressItem = onProgress;

            // Complete handling
            scope.uploader.onCompleteAll = onCompleteAll;

            // Complete upload of single file
            scope.uploader.onCompleteItem = onCompleteItem;
          }
        });

        scope.$watchGroup(['form.$submitted', 'viewConfiguration.showErrors'], function (newValues) {
          if ((newValues[0] || newValues[1]) && !checkValidity()) {
            scope.errorMessage = $translate.instant(`${scope.viewConfiguration.ns}.error.required`);
          }
        });

        scope.$watch('tiers', function (tiers) {
          if (tiers && tiers.pieces) {
            const pieceTiers = _.find(tiers.pieces, function (piece) {
              return piece && piece.reference === scope.piece.reference;
            });
            if (pieceTiers && pieceTiers.documents && pieceTiers.documents.length > 0) {
              scope.documentsTiers = pieceTiers.documents;
            }
          }
        });

        // Check status of the pieces and display error when the form is send
        scope.$watch(
          'piece',
          function () {
            if (scope.piece) {
              // Define default's values
              scope.piece.obligatoire = scope.piece.obligatoire || false;
              scope.piece.envoiPostal = scope.piece.envoiPostal || false;
              $translate.refresh().then(function () {
                applyLabel('libelle', 'labels');
                applyLabel('description', 'descriptions');
              });
              scope.piece.displayListDocuments = angular.isDefined(scope.piece.displayListDocuments)
                ? scope.piece.displayListDocuments
                : true;

              if (!scope.remoteValidation) {
                scope.form.$submitted = false;

                scope.model.$setValidity('required', checkValidity());
              }
            }
          },
          true
        );

        /**
         *
         * @param {string} key
         * @param {string} path
         */
        function applyLabel(key, path) {
          if (!_.get(scope.piece, key)) {
            const labelKey = `${scope.viewConfiguration.ns}.${path}.${scope.piece.reference}`;
            const translation = $translate.instant(labelKey);
            // if the translation equals the key, the translation doesn't exist
            _.set(scope.piece, key, {
              value: translation !== labelKey ? translation : '', // LocalizedText
            });
          }
        }

        /**
         * Indicates if a piece can be associated to at least one planfinancement line
         *
         * @returns {boolean}
         */
        function canPieceBeAssociated() {
          // Fast return if there is no poste available for association.
          if (!availablePostesForAssociation.length) {
            return false;
          }

          // Get all 'pieces' from teleservice template.
          let piecesLigneTS =
            JSONPath('$.depense.postes..[piecesObligatoires,piecesFacultatives][*]', planFinancementTemplate) || [];
          piecesLigneTS = _.uniqBy(piecesLigneTS, 'reference');

          // Check if the 'piece' match with the list of piece from Teleservice template.
          return !!_.find(piecesLigneTS, (piece) => piece.reference === scope.piece.reference);
        }

        /**
         * Gets available postes and lines from the demande having the piece in piecesObligatoires or piecesFacultatives
         *
         * @returns {object[]}
         */
        function getAvailablePostesForAssociation() {
          // Get all postes from the planFinancement
          const postesPF = _.get(scope, 'aide.planFinancement[0].depense.postes', []);

          if (!postesPF.length) {
            return [];
          }

          return _.reduce(
            postesPF,
            (acc, postePF) => {
              const lignesPF = JSONPath('$..lignes[?(@.montant && (@.montant.ht || @.montant.ttc))]', postePF);

              _.forEach(lignesPF, (lignePF) => {
                const referenceUsedToGetRequiredPieces = lignePF.createdByUser ? postePF.reference : lignePF.reference;

                // Get the poste or the category, from the teleservice template.
                let allPiecesTS = JSONPath(
                  `$.depense.postes..[?(@.reference == "${referenceUsedToGetRequiredPieces}")][piecesObligatoires,piecesFacultatives][*]`,
                  planFinancementTemplate
                );

                allPiecesTS = _.uniqBy(allPiecesTS, 'reference');

                const correspondingTSLignePiece = _.find(allPiecesTS, (p) => p.reference === scope.piece.reference);
                if (correspondingTSLignePiece) {
                  // Add the poste if it doesn't exists
                  let poste = _.find(acc, (p) => p.reference === postePF.reference);
                  if (!poste) {
                    poste = _.cloneDeep(postePF);
                    poste.lignes = [];
                    acc.push(poste);
                  }

                  // Add line to the poste
                  poste.lignes.push(lignePF);
                }
              });

              return acc;
            },
            []
          );
        }

        /**
         * Compute expenses to display under the pieces' documents
         * For each document, we get the postes associated
         */
        function computeExpenses() {
          scope.expenses = {
            limit: 3,
          };

          const postesWithLignes = _.get(scope.aide, 'planFinancement.0.depense.postes', []).filter(
            (poste) => !_.isEmpty(poste.lignes)
          );

          _.forEach(scope.piece.documents, (documentPiece) => {
            // Initialize expenses for this document
            scope.expenses[documentPiece.id] = {
              displayRelatedExpenses: false,
              related: {
                items: [],
              },
            };

            // Get lines with this document
            _.forEach(postesWithLignes, (poste) => {
              _.forEach(poste.lignes, (ligne) => {
                // Fast return if there is no amount on the line
                if (!_.get(ligne, 'montant.ht') && !_.get(ligne, 'montant.ttc')) {
                  return;
                }

                const lignePiece = _.find(_.get(ligne, 'pieces', []), (p) => p.reference === scope.piece.reference);
                const matchingDocument = _.find(_.get(lignePiece, 'documents', []), (d) => d.id === documentPiece.id);
                if (matchingDocument) {
                  scope.expenses[documentPiece.id].related.items.push({
                    title: [_.get(poste, 'libelle.value'), _.get(ligne, 'libelle.value')].join(' / '),
                    ligne,
                  });
                }
              });
            });
          });
        }

        /**
         * Tells if the briefcase button should be displayed or not
         *
         * @returns {boolean} should show briefcase
         */
        scope.showBriefcaseButton = () => {
          return (
            scope.tiers?.pieces &&
            scope.documentsTiers?.some(
              (tiersDocument) => tiersDocument?.expand?.properties?.['cmis:name']?.value !== undefined // properties is empty in case of API error (eg: document not found)
            ) &&
            !isDemandeInDepotDelegue
          );
        };

        /**
         * Checks if upload is disabled on pieces
         *
         * @returns {boolean}
         */
        scope.isUploadDisabled = () => {
          return (
            (scope.isActiveOneDocumentByPiece && scope.piece?.documents?.length) ||
            scope.piece.hasDocumentOnProgress ||
            scope.isUploadInProgress
          );
        };
      },
    };
  },
]);
